import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout"
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function SemiCharmedLife() {
  return (
    <Layout>
      <Helmet title="Semi Charmed Life | Third Eye Blind" />

      <h1>Semi Charmed Life</h1>

      <h2>Third Eye Blind</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Chorus>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>Doo doo doo</p>
        </Chorus>
        <Verse>
          <p>I'm packed and I'm holding</p>
          <p>I'm smiling, she's living, she's golden</p>
          <p>She lives for me, says she lives for me</p>
          <p>Ovation, her own motivation</p>
          <p>She comes round and she goes down on me</p>
        </Verse>
        <Verse>
          <p>And I make you smile, like a drug for you</p>
          <p>Do ever what you wanna do, coming over you</p>
          <p>Keep on smiling, what we go through</p>
          <p>One stop to the rhythm that divides you</p>
        </Verse>
        <Verse>
          <p>And I speak to you like the chorus to the verse</p>
          <p>Chop another line like a coda with a curse</p>
          <p>Come on like a freak show takes the stage</p>
          <p>We give them the games we play, she said</p>
        </Verse>
        <PreChorus>
          <p>I want something else to get me through this</p>
          <p>Semi-charmed kinda life, baby, baby</p>
          <p>I want something else, I'm not listening when you say good-bye</p>
        </PreChorus>
        <Chorus>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>Doo doo doo</p>
        </Chorus>
        <Verse>
          <p>The sky was gold, it was rose</p>
          <p>I was taking sips of it through my nose</p>
          <p>And I wish I could get back there, someplace back there</p>
          <p>Smiling in the pictures you would take</p>
          <p>Doing crystal meth, will lift you up until you break</p>
        </Verse>
        <Verse>
          <p>It won't stop, I won't come down</p>
          <p>I keep stock with a tick-tock rhythm, a bump for the drop</p>
          <p>And then I bumped up, I took the hit that I was given</p>
          <p>Then I bumped again, then I bumped again</p>
          <p>I said</p>
        </Verse>
        <PreChorus>
          <p>How do I get back there to the place where I fell asleep inside you</p>
          <p>How do I get myself back to the place where you said</p>
        </PreChorus>
        <PreChorus>
          <p>I want something else to get me through this</p>
          <p>Semi-charmed kinda life, baby, baby</p>
          <p>I want something else, I'm not listening when you say good-bye</p>
        </PreChorus>
        <Verse>
          <p>I believe in the sand beneath my toes</p>
          <p>The beach gives a feeling, an earthy feeling</p>
          <p>I believe in the faith that grows</p>
          <p>And that four right chords can make me cry</p>
          <p>When I'm with you I feel like I could die</p>
          <p>And that would be alright, alright</p>
        </Verse>
        <Bridge>
          <p>And when the plane came in, she said she was crashing</p>
          <p>The velvet it rips in the city, we tripped on the urge to feel alive</p>
          <p>Now I'm struggling to survive</p>
        </Bridge>
        <Bridge>
          <p>Those days you were wearing that velvet dress</p>
          <p>You're the priestess, I must confess</p>
          <p>Those little red panties they pass the test</p>
          <p>Slides up around the belly, face down on the mattress</p>
        </Bridge>
        <Bridge>
          <p>One</p>
          <p>And you hold me, and we are broken</p>
          <p>Still it's all that I wanna do, just a little now</p>
          <p>Feel myself, heading off the ground</p>
          <p>I'm scared, I'm not coming down</p>
          <p>No, no</p>
          <p>And I won't run for my life</p>
          <p>She's got her jaws now locked down in a smile</p>
          <p>But nothing is alright, alright</p>
        </Bridge>
        <PreChorus>
          <p>And I want something else to get me through this life</p>
          <p>Baby, I want something else</p>
          <p>Not listening when you say</p>
          <p>Goodbye, goodbye, goodbye, goodbye</p>
        </PreChorus>
        <Chorus>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>Doo doo doo, doo doo-doo doo</p>
        </Chorus>
        <Verse>
          <p>The sky was gold, it was rose</p>
          <p>I was taking sips of it through my nose</p>
          <p>And I wish I could get back there</p>
          <p>Someplace back there, in the place we used to start</p>
        </Verse>
        <Chorus>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>Doo doo doo, doo doo-doo doo</p>
          <p>I want something else</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
